
















































import { Component, Prop, Vue } from "vue-property-decorator";
import { PropertyUnit } from "@/types/property";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";
import AdminLangCodeNameTable from "@/components/admin/language/name/AdminLangCodeNameTable.vue";

/**
 * AdminPropertyUnitTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
        AdminLangCodeNameTable,
    },
})
export default class AdminPropertyUnitTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;
    @Prop({ type: Array, required: true })
    readonly propertyUnits!: PropertyUnit[];

    private defaultPropertyUnitItem: PropertyUnit = {
        id: -1,
        names: [],
        idAdditive: "",
        image: null,
    };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$tc("language.name").toString(),
                value: "names",
            },
            {
                text: this.$t("shop.article.property.needsValue").toString(),
                value: "idAdditive",
            },
        ];
    }
}
